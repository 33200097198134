































































































































import { Component, Provide } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import QuestionsListFiltersComponent from '@/components/filters/questionsListFilters.component.vue';
import PaginationComponent from '@/components/pagination.component.vue';
import ScopeComponent from '@/components/scope.component.vue';
import QuestionsListMixin from '../../mixins/questionsList.mixin';
import { APPIDS, QUESION_TYPES, REVIEW_QUESTIONS_ROUTES_NAMES } from '../../enums';
import { QuestionsFiltersResponse } from '../../types/questions.type';
import ReviewQuestionsProvider from '../../providers/reviewQuestions.provider';
import SelectedCourse from '../SelectedCourse.component.vue';

@Component({
  components: {
    QuestionsListFiltersComponent,
    PaginationComponent,
    SelectedCourse,
    ScopeComponent,
  },
})
export default class QuestionsList extends mixins(QuestionsListMixin) {
  @Provide() Provider = ReviewQuestionsProvider;

  appId: string = APPIDS.REVIEW_QUESTIONS;

  courseId: string = this.$route?.params.courseId;

  userRights: string[] = [];

  showReviewQuestionsFilters = true;

  reviewDateFromKey = Math.random();

  reviewDateToKey = Math.random();

  filtersData: QuestionsFiltersResponse = {
    questionTypes: [],
  };

  async mounted() {
    this.$store.commit('selectCourse', { course: { courseId: this.courseId } });
  }

  resetSearch() {
    this.reviewDateFromKey = Math.random();
    this.reviewDateToKey = Math.random();
    Object.keys(this.filtersInputs).forEach((key: string) => {
      if (key !== 'validFilters') {
        this.filtersInputs[key] = (key === 'typeId') ? QUESION_TYPES.MCQ.id : '';
      }
    });

    this.search();
  }

  get canEdit() {
    return this.userRights.includes('9.4.');
  }

  get headers() {
    return [
      {
        text: this.$t('tableHeaders.index'),
        sortable: false,
        align: 'center',
        value: 'index',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.questionTitle'),
        sortable: true,
        value: 'questionTitle',
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.ILOs'),
        sortable: false,
        value: 'ILO',
        align: 'center',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.questionType'),
        sortable: false,
        align: 'center',
        value: 'questionType',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.subject'),
        sortable: false,
        align: 'center',
        value: 'subject',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.questionReviewDate'),
        sortable: false,
        align: 'center',
        value: 'reviewRequestDate',
        class: this.headerClass,
      },
      {
        text: this.$t('tableHeaders.questionLanguage'),
        sortable: false,
        align: 'center',
        value: 'questionLanguage',
        class: this.headerClass,
      },
    ];
  }

  setFilters(filters) {
    this.filtersData = filters;
  }

  goCoursesList() {
    const { courseFilters } = this.$store.state.filters;
    this.$router.push({ name: REVIEW_QUESTIONS_ROUTES_NAMES.COURSES_LIST, query: courseFilters });
  }

  goQuestionReview(question) {
    const { questionId, typeId } = question;
    this.$router.push({
      name: 'question-review',
      params: {
        questionId,
        questionType: typeId,
        courseId: this.courseId,
      },
    });
  }
}
